import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { tns } from 'tiny-slider/src/tiny-slider'
import { TinySliderInstance, TinySliderSettings } from 'tiny-slider'
import React from 'jsx-dom'
import { deepMerge } from 'collapsable.js'

interface HTMLCarouselElement extends HTMLElement {
	carousel: TinySliderInstance
}

const breakpoints = {
	xs: '375px',
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1280px'
} as const

type Breakpoint = keyof typeof breakpoints

class CarouselControl implements Control {
	private mediaQueries: Partial<Record<Breakpoint, MediaQueryList>> = {}
	private carouselsPerMedia: Record<Breakpoint, HTMLElement[]>

	public constructor() {
		this.carouselsPerMedia = {} as Record<Breakpoint, HTMLElement[]>

		Object.keys(breakpoints).forEach((breakpoint) => {
			this.carouselsPerMedia[breakpoint as Breakpoint] = []
		})
	}

	public initialize(context: Element | Document): void {
		const carousels = context.querySelectorAll<HTMLElement>('.js-carousel')

		carousels.forEach((carouselWrapper: HTMLElement) => {
			const breakpoint = carouselWrapper.dataset.carouselMinBreakpoint

			if (breakpoint === undefined) {
				this.initializeCarousel(carouselWrapper)
			} else {
				this.initializeResponsiveCarousel(breakpoint as Breakpoint, carouselWrapper)
			}
		})
	}

	public destroy(context: Element) {
		const carousels = context.querySelectorAll<HTMLElement>('.js-carousel')

		carousels.forEach((carouselWrapper: HTMLElement) => {
			Object.keys(breakpoints).forEach((key) => {
				const breakpoint: Breakpoint = key as Breakpoint
				const index = this.carouselsPerMedia[breakpoint].indexOf(carouselWrapper)

				if (index > -1) {
					this.carouselsPerMedia[breakpoint].splice(index, 1)
				}
			})
		})
	}

	private handleMediaQueryChange(event: MediaQueryListEvent, breakpoint: Breakpoint): void {
		this.carouselsPerMedia[breakpoint].forEach((carouselWrapper: HTMLElement) => {
			if (event.matches) {
				this.initializeCarousel(carouselWrapper, breakpoint)
			} else if ('carousel' in carouselWrapper) {
				this.destroyCarousel(carouselWrapper as HTMLCarouselElement)
			}
		})
	}

	private getMediaQueriesList(breakpoint: Breakpoint): MediaQueryList {
		if (this.mediaQueries[breakpoint] === undefined) {
			const mediaQuery = window.matchMedia(`(min-width: ${breakpoints[breakpoint]}`)

			mediaQuery.addEventListener('change', (event) => {
				this.handleMediaQueryChange(event, breakpoint)
			})

			this.mediaQueries[breakpoint] = mediaQuery
		}

		return this.mediaQueries[breakpoint] as MediaQueryList
	}

	private initializeResponsiveCarousel(breakpoint: Breakpoint, carouselWrapper: HTMLElement) {
		this.carouselsPerMedia[breakpoint].push(carouselWrapper)

		if (this.getMediaQueriesList(breakpoint).matches) {
			this.initializeCarousel(carouselWrapper, breakpoint)
		}
	}

	public initializeCarousel(carouselWrapper: HTMLElement, breakpoint?: Breakpoint): void {
		const carouselElement = carouselWrapper.querySelector<HTMLElement>('.js-carousel__carousel')
		const itemsCount = carouselElement ? carouselElement.querySelectorAll('.js-carousel__item').length : 0

		if (itemsCount <= 1 || !carouselElement) {
			return
		}

		const dataOptions = JSON.parse(carouselWrapper.dataset.carousel || '{}')
		const controls = this.buildControlsContainer(carouselWrapper, carouselElement, breakpoint)
		const nav = this.buildNavContainer(carouselWrapper, itemsCount, breakpoint)

		const defaultOptions: TinySliderSettings = {
			container: carouselElement,
			controlsContainer: controls,
			navContainer: nav,
			loop: true
		}

		const options = deepMerge({}, defaultOptions, dataOptions)

		;(carouselWrapper as HTMLCarouselElement).carousel = tns(options)
	}

	private destroyCarousel(carouselWrapper: HTMLCarouselElement) {
		carouselWrapper.carousel.destroy()

		carouselWrapper
			.querySelectorAll<HTMLElement>('.js-carousel__controls, .js-carousel__nav')
			.forEach((element) => (element.hidden = true))
	}

	private buildControlsContainer(
		carouselWrapper: HTMLElement,
		carouselElement: HTMLElement,
		breakpoint?: Breakpoint
	): HTMLElement {
		const controls = carouselWrapper.querySelector<HTMLElement>('.js-carousel__controls')

		if (controls) {
			controls.hidden = false
			return controls
		}

		const customControls: Element = (
			<p
				className={[
					'm-0 pointer-events-none hidden:hidden',
					breakpoint ? `max-${breakpoint}:hidden` : null,
					'js-carousel__controls'
				]}
			>
				<button type="button" class="sr-only">
					Předchozí
				</button>
				<button type="button" class="btn btn--secondary absolute top-1/4 right-0 z-1 w-5 h-5 min-h-0 p-0 shadow-none">
					<span class="btn__inner btn__inner--text">
						<i class="icon icon--arrow-right" aria-hidden="true"></i>
						<span class="sr-only">Další</span>
					</span>
				</button>
			</p>
		)

		carouselElement.insertAdjacentElement('beforebegin', customControls)

		return customControls as HTMLElement
	}

	private buildNavContainer(carouselWrapper: HTMLElement, itemsCount: number, breakpoint?: Breakpoint): HTMLElement {
		const nav = carouselWrapper.querySelector<HTMLElement>('.js-carousel__nav')

		if (nav && nav.childElementCount === itemsCount) {
			nav.hidden = false
			return nav
		}

		const customNav: Element = (
			<p
				className={[
					'flex justify-center gap-1 h-[7px] mt-3 hidden:hidden',
					breakpoint ? `max-${breakpoint}:hidden` : null,
					'js-carousel__nav'
				]}
			></p>
		)

		for (let i = 0; i < itemsCount; i++) {
			customNav.appendChild(
				<button
					type="button"
					class="h-[7px] rounded-full bg-blue/10 transition-all js-carousel__page"
					style="display: none"
				></button>
			)
		}

		carouselWrapper.insertAdjacentElement('beforeend', customNav)

		return customNav as HTMLElement
	}
}

export default new CarouselControl()
